<template>
  <transition name="modal">
    <div v-if="showModal" class="modal-mask" @click="$emit('close')">
      <div class="modal-wrapper">
        <div class="modal-container" :class="cssClass" @click.prevent.stop>
          <div class="modal-header">
            <slot name="header"> </slot>
          </div>
          <div class="modal-body">
            <slot name="body"> </slot>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <button class="close-modal-btn" @click="$emit('close')">
                Cerrar
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    cssClass: {
      type: String,
      required: false,
    },
  },
  setup() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: all 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.modal-container {
  width: 90%;
  max-width: 300px;
  margin: 30px auto;
  padding: 20px 30px 38px 30px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;

  @media (min-width: 768px) {
    width: 50%;
    max-width: 600px;
  }
}

.modal-body {
  margin: 20px 0;
}

.modal-footer {
  display: flex;
  margin-top: 40px;
  justify-content: center;
}

.modal-enter-from {
  opacity: 0;
}

.modal-enter-to {
  opacity: 1;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter-active {
  transition: all 0.6s ease;
}

.modal-leave-active {
  transition: all 0.2s ease;
}
</style>